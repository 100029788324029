
a:hover{
  cursor:pointer;
}

.buttons{
  display:none;
}

.buttons .btn{
  margin-bottom:3px;
}

.provisioningForm,.provisioningHSDForm,.provisioningComboForm{
  display:none;
}

.provisioning-forms .form-group label{
  width: 100%;
}

/*Temp fix*/
#provisionForm .modal-body,#provisionHSDForm .modal-body{
  overflow-y:visible;
}

.disabled{
    color: #847e7e !important;
    box-shadow: inset 0 0 0 2px #c7b9b9 !important;
}

.td_label{
  font-size: 10px;
  color: #716d6d;
}
#ogg{
  display:none;
}

.center{text-align:center}
#space-invaders {
  margin: 0 auto;
  display: block;
  background: white
}

#generatedXML textarea{
    width:100%;
    max-width:100%;
}

.pon_loader{
  text-align:center;
  padding: 10px;
}

.overflow{
	overflow:auto;
}

.recentOrder,
/*.searchResults a, */
.recentError{
    display:none;
}

/*.searchResults a {
  text-align:right;
}*/


.panel-title a {
	display:block;
}


.searchResults{
  width:100%;
  float:left;
}

.center_me{
  text-align:center;
}

.center_me .mcc-button, .center_me button, .center_me [type="button"]{
  margin-right:0;
}

.loader,.recentLoader{
  display:none;
  text-align: center;
  margin: 40px;
}

/* Important part */
.modal-dialog{
    overflow-y: initial !important
}
.modal-body{
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}



.anti-scroller {
  max-height: 200px;
	overflow-y: auto;
  overflow-x: hidden;
}


#yp_search_results,#ddf_search_results{
  display:none;
}

.searchResults table a{
  display:block;
}


.searchResults table a.searchOM {
  display: inline-block;
  margin-left: 5px;
}

.results{
  max-height:400px;
}

.device_tns .sortable,#unassigned_tns .sortable{
    border: 1px solid #eee;
    width: 100%;
    min-height: 20px;
    margin: 0;
    padding: 5px 0 0 0;
    float: left;
    margin-right: 10px;
}

.ui-state-highlight { height: 1.5em; line-height: 1.2em; }

.sortable li{
    margin: 0 5px 5px 5px;
    padding: 5px;
    font-size: 1.2em;
    width: 100%;
  display: list-item;
  list-style-position: inside;
}

  /*============================================================================
    = MCC CUSTOM UTILITY CLASSES */

  /* padding */
    .no-padding {
      padding: 0 !important;
    }

    .no-padding-vertical {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .no-padding-horizontal {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .no-padding-top {
      padding-top: 0 !important;
    }

    .no-padding-bottom {
      padding-bottom: 0 !important;
    }

    .no-padding-left {
      padding-left: 0 !important;
    }

    .no-padding-right {
      padding-right: 0 !important;
    }

    .visuallyhidden {
      visibility: hidden;
    }

    .italic {
      font-style: italic;
    }

/* add margin */
.add-margin {
  margin: 15px !important;
}

.add-margin-left {
  margin-left: 15px !important;
}

.add-margin-right {
  margin-right: 15px !important;
}

.add-margin-top {
  margin-top: 15px !important;
}

.add-margin-bottom {
  margin-bottom: 15px !important;
}

/*Button Icon Hover Ani*/
.ico-btn-hover {
  max-width: 36px;
  transition: all 300ms ease;
}

.ico-btn-hover:hover {
  max-width: 250px;
}

.ico-btn-hover i {
  margin-right: 5px;
}

.flex-row {
  display: flex;
}

.bordered-note {
  border-top: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
}

.hide_until_selected{
  display:none;
}

.page-content-wrapper .page-content{
    padding-top: 50px;
    margin-left: 0px !important;
}

@media (min-width: 992px){
.page-sidebar-closed .page-content-wrapper .page-content {
    margin-left: 0px!important;
}
}

.logout{
    display: block;
    padding-top: 15px;
    padding-right: 15px;
    color: white;
}

.logout:hover{
  color: white;
}