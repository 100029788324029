#ogg{
	display:none;
}

#space-invaders {
  margin: 0 auto;
  display: block;
  background: white
}

#ogg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999;
}