/*==============================================================================
  Mediacom Cable Maestro Theme
--------------------------------------------------------------------------------
  Colors

  mcc-blue:                   rgb(0, 85, 183)

  mcc-blue-light:             rgb(1, 150, 230)

  mcc-blue-dark:              rgb(7, 53, 106)

  mcc-green:                  rgb(39, 174, 96)

  mcc-green-ALT:              rgb(75, 215, 134)

  mcc-purple:                 rgb(112, 32, 130)

  mcc-purple-ALT:             rgb(165, 47, 191)

  mcc-red:                    rgb(214, 0, 28)

  mcc-red-ALT:                rgb(255, 36, 64)

  mcc-mint:                   rgb(0, 164, 153)

  mcc-mint-ALT:               rgb(0, 215, 201)

  mcc-orange:                 rgb(249, 157, 28)

  mcc-orange-ALT:             rgb(251, 189, 103)
================================================================================
==============================================================================*/


  /*============================================================================
    = MCC CUSTOM GLOBAL */


    html {
      /* override error message styles from putting padding in global element */
      padding: 0 !important;
    }

    img {
      max-width: 100%;
    }

    input[type="button" i],
    input[type="submit" i],
    input[type="reset" i],
    input[type="file" i]::-webkit-file-upload-button,
    button  {
      background: transparent;
    }

  /*============================================================================
    = MCC CUSTOM STYLES */

    /* buttons
    ==========*/
    .mcc-btn-blue {
      background: rgb(0, 85, 183) !important;
      color: white !important;
    }
      .mcc-btn-blue:hover,
      .mcc-btn-blue:focus,
      .mcc-btn-blue:active,
      .btn.mcc-border-blue:hover,
      .badge.mcc-border-blue {
        background: rgb(1, 150, 230) !important;
        color: white !important;
      }

    .mcc-btn-blue-light {
      background: rgb(1, 150, 230) !important;
      color: white !important;
    }
      .mcc-btn-blue-light:hover,
      .mcc-btn-blue-light:focus,
      .mcc-btn-blue-light:active,
      .btn.mcc-border-blue-dark:hover,
      .badge.mcc-border-blue-dark:hover { /* looks like blue-light */

        background: rgb(7, 53, 106) !important;
        color: white !important;
      }

    .mcc-btn-blue-dark {
      background: rgb(7, 53, 106) !important;
      color: white !important;
    }
      .mcc-btn-blue-dark:hover,
      .mcc-btn-blue-dark:focus,
      .mcc-btn-blue-dark:active,
      .btn.mcc-border-blue-light:hover,
      .badge.mcc-border-blue-light:hover { /* looks like blue-dark */
        background: rgb(0, 85, 183) !important;
        color: white !important;
      }

    .mcc-btn-green {
      background: rgb(39, 174, 96) !important;
      color: white !important;
    }
      .mcc-btn-green:hover,
      .mcc-btn-green:focus,
      .mcc-btn-green:active,
      .btn.mcc-border-green:hover,
      .badge.mcc-border-green:hover {
        background: rgb(75, 215, 134) !important;
        color: white !important;
      }

    .mcc-btn-purple {
      background: rgb(112, 32, 130) !important;
      color: white !important;
    }
      .mcc-btn-purple:hover,
      .mcc-btn-purple:focus,
      .mcc-btn-purple:active,
      .btn.mcc-border-purple:hover,
      .badge.mcc-border-purple:hover {
        background: rgb(165, 47, 191) !important;
        color: white !important;
      }

    .mcc-btn-red {
      background: rgb(214, 0, 28) !important;
      color: white !important;
    }
      .mcc-btn-red:hover,
      .mcc-btn-red:focus,
      .mcc-btn-red:active,
      .btn.mcc-border-red:hover,
      .badge.mcc-border-red:hover {
        background: rgb(255, 36, 64) !important;
        color: white !important;
      }

    .mcc-btn-mint {
      background: rgb(0, 164, 153) !important;
      color: white !important;
    }
      .mcc-btn-mint:hover,
      .mcc-btn-mint:focus,
      .mcc-btn-mint:active,
      .btn.mcc-border-mint:hover,
      .badge.mcc-border-mint:hover {
        background: rgb(0, 215, 201) !important;
        color: white !important;
      }

    .mcc-btn-orange {
      background: rgb(249, 157, 28) !important;
      color: white !important;
    }
      .mcc-btn-orange:hover,
      .mcc-btn-orange:focus,
      .mcc-btn-orange:active,
      .btn.mcc-border-orange:hover,
      .badge.mcc-border-orange:hover,
      .mcc-btn-orange:disabled {
        background: rgb(251, 189, 103) !important;
        color: white !important;
      }

      .btn.border-grey-gallery:hover {
        background: rgb(85, 85, 85) !important;
        color: white !important;
      }



    /* backgrounds
    ==============*/

    .mcc-bg-blue {
      background: rgb(0, 85, 183) !important;
    }

    .mcc-bg-blue-light {
      background: rgb(1, 150, 230) !important;
    }

    .mcc-bg-blue-dark {
      background: rgb(7, 53, 106) !important;
    }

    .mcc-bg-green {
      background: rgb(39, 174, 96) !important;
    }

    .mcc-bg-purple {
      background: rgb(112, 32, 130) !important;
    }

    .mcc-bg-red {
      background: rgb(214, 0, 28) !important;
    }

    .mcc-bg-mint {
      background: rgb(0, 164, 153) !important;
    }

    .mcc-bg-orange {
      background: rgb(249, 157, 28) !important;
    }

    /* font colors
    ==============*/
    .mcc-font-blue {
      color: rgb(0, 85, 183) !important;
    }

    .mcc-font-blue-dark {
      color: rgb(7, 53, 106) !important;
    }

    .mcc-font-blue-light {
      color: rgb(1, 150, 230) !important;
    }

    .mcc-font-green {
      color: rgb(39, 174, 96) !important;
    }

    .mcc-font-purple {
      color: rgb(112, 32, 130) !important;
    }

    .mcc-font-red {
      color: rgb(214, 0, 28) !important;
    }

    .mcc-font-mint {
      color: rgb(0, 164, 153) !important;
    }

    .mcc-font-orange {
      color: rgb(249, 157, 28) !important;
    }

    .mcc-font-white {
      color: rgb(255, 255 ,255) !important;
    }

    /* border colors
    =================*/

    [class*="mcc-border"] {
      border: 1px solid;
    }

    .mcc-border-blue {
      border-color: rgb(0, 85, 183) !important;
    }

    .mcc-border-blue-dark {
      border-color: rgb(7, 53, 106) !important;
    }

    .mcc-border-blue-light {
      border-color: rgb(1, 150, 230) !important;
    }

    .mcc-border-green {
      border-color: rgb(39, 174, 96) !important;
    }

    .mcc-border-purple {
      border-color: rgb(112, 32, 130) !important;
    }

    .mcc-border-red {
      border-color: rgb(214, 0, 28) !important;
    }

    .mcc-border-mint {
      border-color: rgb(0, 164, 153) !important;
    }

    .mcc-border-orange {
      border-color: rgb(249, 157, 28) !important;
    }

    .border-cyan-soft {
      border-color: rgb(0, 180, 199) !important;
    }

    /* shadows
    ==========*/
    .mcc-shadow-z1 {
      box-shadow: 0 1px 3px rgba(0,0,0,0.12),
                  0 1px 2px rgba(0,0,0,0.24) !important;
    }

    .mcc-shadow-z2 {
      box-shadow: 0 3px 6px rgba(0,0,0,0.16),
                  0 3px 6px rgba(0,0,0,0.23) !important;
    }

    .mcc-shadow-z3 {
      box-shadow: 0 10px 20px rgba(0,0,0,0.19),
                  0 6px 6px rgba(0,0,0,0.23) !important;
    }

    .mcc-shadow-z4 {
      box-shadow: 0 14px 28px rgba(0,0,0,0.25),
                  0 10px 10px rgba(0,0,0,0.22) !important;
    }

    .mcc-shadow-z5 {
      box-shadow: 0 19px 38px rgba(0,0,0,0.30),
                  0 15px 12px rgba(0,0,0,0.22) !important;
    }

    /* btn shadows */
    .mcc-btn-shadow {
      box-shadow: 0 1px 3px rgba(0,0,0,0.12),
                  0 1px 2px rgba(0,0,0,0.24) !important;
      transition: all 0.3s cubic-bezier(.25,.8,.25,1) !important;
    } /* &:hover */
      .mcc-btn-shadow:hover {
        box-shadow: 0 3px 6px rgba(0,0,0,0.16),
                    0 3px 6px rgba(0,0,0,0.23) !important;
      }

      .mcc-hover-shadow {
        box-shadow: 0 1px 3px rgba(0,0,0,0.12),
                    0 1px 2px rgba(0,0,0,0.24) !important;
        transition: all 0.3s cubic-bezier(.25,.8,.25,1) !important;
      } /* &:hover */
      .mcc-hover-shadow:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25),
                    0 10px 10px rgba(0,0,0,0.22) !important;
      }

    /**
      -- badges */
    .danger.badge {
      background: rgb(214, 0, 28) !important;
      color: white !important;
    }

    .warning.badge {
      background: rgb(249, 157, 28) !important;
      color: white !important;
    }

    .success.badge {
      background: rgb(39, 174, 96) !important;
      color: white !important;
    }

    .label.badge {
      border-radius: 5px !important;
    }

    button.badge{
      border:0px;
    }

    .badge[class*="mcc-border"] {
      background: white;
      border: 1px solid;
    }

    /**
      -- block classes */

    .block {
      display: block;
    }

    .inline-block {
      display: inline-block;
    }

    .inline {
      display: inline;
    }

    .badge.badge-empty {
      margin-left: 5px;
    }

    .mt-element-list .list-simple.mt-list-container ul>.mt-list-item {
      border: 0;
      border-left: 3px solid;
    }


/*==============================================================================
  = Page Theme Overrides =*/

/* LOGIN
=========*/

    /* login background */
    #login {
      background: white;
    }

    /* login logo image */
    #login .login-logo {
      position: relative;
      top: initial;
      left: initial;
    }

    /* login form */
    #loginForm {
      margin-top: 0;
    }

    /* login error messages */
    #login .user-login-5 .alert {
      margin-top: 0;
    }

    /* tablet+ */
    @media screen and (min-width: 1024px) {

      #login .login-logo {
        position: absolute;
        top: 2rem;
      }

    }

/* HOME PAGE
====================*/
.mae-toolbox {
  display: flex;
  flex-direction: column;
  min-height: 200px;
  justify-content: center;
}

/* ORDER MANAGEMENT
====================*/

    #clearResults,
    #refreshResults
     {
      display:none;
    }

    #searchResults #results {
      display: none;
    }

    .refreshResults {
      text-align: right;
      padding-left: 30px;
      border-radius: 0 25px 25px 0 !important;
      position: relative !important;
      z-index: 99999 !important;
    }



    .clearResults {
      border-radius: 0 25px 25px 0 !important;
      padding-left: 35px;
      text-align: right;
      transform: translateX(-15px);
      position: relative !important;
      z-index: 99;
    }

    .searchResults {
      margin-top: 2rem;
    }

    .search-utl-btn-group {
      transform: translate(-45px, 0px);
    }

    .notes {
      padding: 10px;
      margin: 0 0 10px;
      border: 1px solid;
    }

    .notes .row,
    .customer-wrapper .row,
    .order-wrapper .row,
    .order-information .row,
    .parent_info .row,
    .switch_accordion .row,
    .config_info .row
    #device_info_wrapper .row {
      padding: 5px 0 !important;
      margin: 0;
      /*word-break: break-all;*/
    }

    .table-view .row:nth-of-type(even) {
      background: rgb(225, 229, 236);
    }

    .table-view .row:nth-of-type(even) * {
      background: transparent;
    }

    .config_info {
      font-size: 13px;
    }

    .product_info .row {
      padding: 5px 0;
    }

    #product_info_wrapper{
      word-wrap: break-word;
      clear: both;
    }

    .order-utilities {
      margin-bottom: 15px;
    }

    .results-portlet .portlet-title {
      padding: 0 15px;
    }

    .pon_generator {
      display: flex;
      margin-bottom: 15px;
    }

    .add_pon_label {
      flex: 1;
    }

    .mt-clipboard-container {
      padding: 0;
    }

    .mt-clipboard-container .mt-clipboard {
      margin: 0;
    }

    .mt-clipboard:hover {
      cursor: pointer;
    }

    .status.badge {
      margin: 0 3px;
    }

    .order-utilities-buttons {
      list-style: none;
      padding: 0;
    }

    .order-utilities-buttons li {
      padding-bottom: 5px;
    }

    .accordion .panel .panel-title .accordion-toggle {
      padding-right: 20px;
    }

    .order-due-date {
      margin-left: 5px;
    }

    .switchValues .fqdn{
      word-wrap: break-word;
    }

    /* custom bootstrap switch styles for account/order switch */
    .bootstrap-switch {
      border-radius: 25px !important;
    }

    .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-account,
    .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-account {
      color: white;
      background: rgb(61, 61, 61);
    }

    .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-order,
    .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-order {
      color: white;
      background: rgb(0, 164, 153);
    }

    .device_table .notes {
      min-height: 150px;
    }

    /* OM MODALS */
    .yp_search_item.company_name {
      border-bottom: 1px solid;
    }

    .modal-body .nav-tabs.tabs-left {

    }

    .tabs-left.nav-tabs>li.active>a {
      background: rgb(225, 229, 236);
    }

    .table>thead>tr>th,
    .table>tbody>tr>td {
        border-top: 1px solid rgb(180, 180, 180);
        border-bottom: 1px solid rgb(180, 180, 180);
    }

    #cache_session_data_block{
        margin-top:70px;
    }

    .accordion-utilities th, .accordion-utilities td{
        word-wrap: break-word;
        max-width: 25vw;
    }

    .table.utilities .table.table-bordered>tbody>tr>td {
        font-size: 75%;
        max-width: 20vw;
    }

    nav.nav-utilities{
        position: fixed;
        z-index: 2;
        background-color: #ffffffAA;
        box-shadow: 0px 0px 7px #ffffffAA;
        padding:15px;
        margin-top: -15px;
    }


    .page-log .todo-sidebar{
        width:230px;
    }
    .page-log .todo-sidebar .portlet{
        padding: 12px 20px 15px;
    }
    .page-log .todo-sidebar .portlet .todo-project-list-content{
        height: auto;
    }
    .page-log tr.local-info{
        color: #377;
        background-color: #33777709;
    }
    .page-log tr.local-error{
        color: #933;
        background-color: #9933330F;
    }
    .page-log td.time{
        white-space: nowrap;
    }
    .page-log td.type{
        font-weight:bold;
    }
    .page-log td.msg{
        white-space: pre-wrap;
        font-family: monospace;
        word-wrap: break-word;
    }

    .page-log .portlet{
        border:1px solid;
    }

    .page-log a.current-link {
        opacity: 0.66;
        background-color: #0001;
        cursor: default;
    }

    .page-log table.dataTable thead .sorting {
        background-image: url(/build/assets/img/sort_both.png);
    }

    .page-log table.dataTable thead .sorting_asc{
        background-image: url(/build/assets/img/sort_asc.png);
    }

    .page-log table.dataTable thead .sorting_desc {
        background-image: url(/build/assets/img/sort_desc.png);
    }

    .page-log #log_table{
        display:none;
    }

    @media (min-width: 1405px){
        .page-log .col-md-3 {
            width: initial;
        }
        .page-log .col-md-9{
            width: 80%;
        }
    }

    .tab-pane {
        padding: 15px 0 0;
    }

    .nav>li>a {
      padding: 10px 5px;
    }

    .transfers {
      margin-bottom: 15px;
    }

    .active-products .tab-pane {
      min-height: 200px;
    }

    .active-products-nav {
      display: flex;
    }

    .active-products-nav li {
      flex-grow: 1;
    }

    .active-products-nav li a {
      background: #e5e5e5;
    }

    .tabbable-custom > .active-products-nav.nav-tabs > li > a:hover {
      background: #f5f5f5;
    }

    .active-products-nav li.active a {
      background: white;
    }

    .mt-repeater .mt-repeater-item {
      border-bottom: 0;
    }

    .modal-title.divider-title{
      margin-top: 2.5em;
      margin-right: 18%;
      text-align: center;
    }

    .modal-title.divider-title::before,
    .modal-title.divider-title::after {
      display: inline-block;
      border-top: 2px solid #DDD;
      width: calc(45% - 100px);
      height: 5px;
      content: "";
      margin-left: 1em;
      margin-right: 1em;
    }

/* AUDIT LOG
====================*/

    .searchResultsNoMargin {
      margin-top:0px;
    }

    .error{
      color:red;
    }

    .ui-spinner{
      width: 100%;
      height: 34px;
      background-color: #fff !important;
      border: 1px solid #c2cad8;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
      -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
      -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
      transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    }

    textarea.form-control.xml_response{
      height:150px;
    }

    .mcc-switch-li {
      padding: 5px 15px !important;
      display: flex;
      align-items: center;
    }

    .mcc-switch-li .list-item-content {
      padding-left: 15px !important;
      padding-right: 0 !important;
      width: 100%;
    }

    .fullSlider {
      width: calc(100% - 30px);
      padding: 0 3px;
    }

    .msgToggle,
    .resultsToggle {
      padding: 0;
      width: 15px;
      transform: translateY(6px);
    }

    .nodeToggle {
      color: rgb(27, 188, 155);
      cursor: pointer;
    }

    .nodeToggle:hover {
      color: rgb(21, 143, 118);
    }

    .nodeToggle::selection {
      background: transparent;
    }

    .resultsToggle {
      text-align: right;
    }

    /* custom slider styles */
    .mcc-search .irs-bar,
    .mcc-search .irs-from,
    .mcc-search .irs-to,
    .mcc-search .irs-single {
      background: rgb(27, 188, 155);
    }

    #auditSearch #pagination {
      margin: 0 15px !important;
    }

    #pagination>.pagination>li>a {
      padding: 5px 10px;
      font-size: 12px;
      line-height: 1.5;
      width: 40px;
      text-align: center;
    }

    #auditSearch .portlet-title {
      text-align: right;
    }

    .pagination {
      margin: 5px;
    }

    .pagination .prev a,
    .pagination .next a {
      width: 30px !important;
    }

    .pagination .prev a{
      border-radius: 50% 0 0 50% !important;
    }

    .pagination .next a{
      border-radius: 0 50% 50% 0 !important;
    }

    #pagination .pagination>.disabled>a,
    #pagination .pagination>.disabled>a:focus,
    #pagination .pagination>.disabled>a:hover,
    #pagination .pagination>.disabled>span,
    #pagination .pagination>.disabled>span:focus,
    #pagination .pagination>.disabled>span:hover {
      background: rgb(27, 188, 155);
      border: 1px solid rgb(27, 188, 155);
      color: white;
    }

    .number-of-pages-results {
      margin: 0 18px;
    }

    .slider-wrapper {
      margin-bottom: 15px;
    }

  /* TECH UI
  ====================*/
  /* prevent modal from dragging on touch devices in provision modal */
  #provision_modal,
  .page-header.navbar-fixed-top {
    max-width: 100vw;
  }
/* DEVICE MANAGEMENT
====================*/

    /* DEVICE MANAGEMENT MODAL */
    #view_devices,#add_devices,#remove_devices,#edit_devices,#swap_devices,#add_hsd_devices,#tivo_devices{
      display:none;
    }
    /*.tivo_device_details*/
    .device_details{
      display:none;
    }

    .tivo-btn{
      padding: 0px;
      margin: 5px 0px;
    }

    /* TD MODAL */
    #view_td,#add_td,#remove_ca{
      display:none;
    }

    .device_details{
      display:none;
    }

    /* CA MODAL */
    #view_ca,#add_ca,#remove_ca,#modify_ca,#manage_ca,#manage_ca_msg,#manage_lan{
      display:none;
    }
    
    /* EERO MODAL */
    #view_eero{
      display:none;
    }    
    
    .wifi-buttons{
      float:right;
    }

    .add_wifi_device_master{
      display:none;
    }

    .add_wifi_device{
      display:inline-block;
    }

    .add_wifi_device, .wifi-repeater-delete{
      margin-top: 25px;
    }

    /* forcibly hide any add buttons beyond the first */
    .wifi-list .wifi-item:nth-child(n+2) .add_wifi_device {
      display: none;
    }

    /* wifi fields validation classes */
    .wifi-list .fa-check {
      border-color:rgb(39, 174, 96);
      box-shadow: 0px 0px 4px 0px rgb(39, 174, 96) !important;
    }

    .wifi-list .fa-warning {
      border-color:rgb(214, 0, 28);
      box-shadow: 0px 0px 4px 0px rgb(214, 0, 28) !important;
    }

    /* View CA subscriber stuff */
    .td_search_item .readableMAC{
      color:#000;
    }

    .ca-details-key-span{
      font-weight: bold;
      color: #666;
      line-height: 1.75em;
    }

   .td_search_item{

   }

    p.td_search_item span{
      font-size: 1.20em;

    }

    p.td_search_item span:first-child{
      /*border-top: 2px solid #eee;*/
      margin-top: +1em;
    }

    .ca-details-span{
      font-weight: normal;
      color: #000;
    }

    .readableMAC, .ca-details-span{
      margin-left: 0.5em;
    }

    /* EMAIL MODAL */
    #view_email,#add_email{
      display:none;
    }

    /* prevent page scrolling with modal open */
    body.modal-open {
      overflow-y: hidden !important;
    }

    .sortable {
      margin: auto;
      padding: 0;
      width: 100%;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .sortable.grid {
      overflow: hidden;
    }

    .sortable li {
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100);
      opacity: 1;
      -webkit-transition: all 0.2s ease;
      -moz-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      -ms-transition: all 0.2s ease;
      transition: all 0.2s ease;
      list-style: none;
      border-radius: 4px;
      margin: 5px;
      padding: 5px;
      font-size: 15px;
    }

    .sortable li:hover {
      cursor: move;
    }

    .sortable li {
      border: 1px solid #163b73;
      background: #47628a;
      color: #fff;
      text-shadow: 0 1px 1px #03214c;
      box-shadow: inset 0 1px 1px rgba(255,255,255,0.7);
    }

    .sortable li i{
      cursor:pointer;
      transition:all 0.5s;
      -webkit-transition:all 0.5s;
      -moz-transition:all 0.5s;
      -o-transition:all 0.5s;
      -ms-transition:all 0.5s;
    }

    .sortable li i:hover{
      color: rgb(247, 47, 32);
      cursor: pointer;
      text-shadow: 0 1px 1px #000000;
    }

    .sortable_disabled {
      opacity: 0.5;
    }

    .sortable_exclude_dynamic {
      margin: auto;
      padding: 0;
      width: 310px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .sortable_exclude_dynamic li {
      border: 1px solid #d97815;
      background: #ff9933;
      color: #fff;
      text-shadow: 0 1px 1px #ac4e00;
      box-shadow: inset 0 1px 1px rgba(255,255,255,0.7);
    }

    .sortable_exclude_dynamic li.correct {
      border:1px solid #000000;
      background:#cccccc;
      cursor:default;
    }

    .sortable_exclude_dynamic li.empty{
      border:none;
      background:none;
      color:#dcdcdc;
      cursor:default;
    }

    .over {
      -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
      box-shadow: 0 5px 10px rgba(0,0,0,0.2);

      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -o-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }

    .moving {
      -webkit-transform: scale(0.9);
      -moz-transform: scale(0.9);
      -o-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
      filter: alpha(opacity=30);
      opacity: 0.3;
    }

    .drop {
      -webkit-animation: drop 1s ease;
      -moz-animation: drop 1s ease;
      -ms-animation: drop 1s ease;
      -o-animation: drop 1s ease;
      animation: drop 1s ease;
    }

    @keyframes "drop" {
      0%, 100% {
        background: #FF9933;
        color: #fff;
      }
      50% {
        color: #FF9933;
        background: #fff;
      }

    }

    @-moz-keyframes drop {
      0%, 100% {
        background: #FF9933;
        color: #fff;
      }
      50% {
        color: #FF9933;
        background: #fff;
      }

    }

    @-webkit-keyframes "drop" {
      0%, 100% {
        background: #FF9933;
        color: #fff;
      }
      50% {
        color: #FF9933;
        background: #fff;
      }

    }

    @-ms-keyframes "drop" {
      0%, 100% {
        background: #FF9933;
        color: #fff;
      }
      50% {
        color: #FF9933;
        background: #fff;
      }

    }

    @-o-keyframes "drop" {
      0%, 100% {
        background: #FF9933;
        color: #fff;
      }
      50% {
        color: #FF9933;
        background: #fff;
      }

    }

    .order-buttons{
      clear:both;
    }

    .order-buttons .btn{
      margin:3px;
    }

/* AUDIT LOG */
    .searchResultsNoMargin{
      margin-top:0px;
    }

    .error{
      color:red;
    }

    .ui-spinner{
      width: 100%;
      height: 34px;
      background-color: #fff !important;
      border: 1px solid #c2cad8;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
      -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
      -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
      transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    }

    textarea.form-control.xml_response{
      height:150px;
    }

    .mt-repeater-item.new_add .mt-initial{
      display:none;
    }

    .mt-repeater-item .subsequent{
      display:none;
    }

    .mt-repeater-item.new_add .subsequent{
      display:initial;
    }

    .mt-repeater-item .form-control[readonly]{
      border:none;
      background-color:transparent;
      opacity:0.8;
      padding:0px;
      height:90%;
    }

    .mt-repeater .mt-repeater-input select{
      min-width: 150px;
    }

    #add_cpe_device, #CA_serialNumber, #p_no_devices, #lan_no_devices{
      display:none;
    }
    

  #auditSearch .panel-group:nth-of-type(even) .panel-heading {
    background: #2F353B;
    color: white;
  }

  #auditSearch .panel-default>.panel-heading {
    color: white;
    background-color: #BFBFBF;
  }
  
    table.group-lan th{
      border: 1px #ddd solid;
      /*background-color: #fbfcfd;*/
      padding: 8px 10px;
    }

    table.group-lan td{
      /*border: 1px #eee solid;
      padding: 8px 10px;*/
    }

    .lan-repeater-item input{
      border:none;
      background-color:transparent;
      width: 100%;
    }

    .lan-repeater-item .iPv4AddressesList{
      cursor:pointer;
      text-decoration:underline;
    }
    .lan-repeater-item .iPv4AddressesList:hover{

    }
    

  #auditSearch .panel .panel-title>a:hover,
  #auditSearch .panel-heading:hover a {
    color: rgb(151, 151, 151);
  }

  .irs-slider.state_hover,
  .irs-slider:hover {
    cursor: pointer;
  }

  .odd .panel-default>.panel-heading{
    background-color: #dbdbdb;
    border-color: #000000;
  }
/*
  #results .panel-group:nth-child(odd) .panel-default>.panel-heading,#results .panel-group:nth-child(odd) .panel{
    background-color:#7f7bc5;
    background:#7f7bc5;
    color:white;
  }
*/
  .audit_results .panel-group .panel-default>.panel-heading{
    overflow:auto;
  }

  .audit_results .panel-group .panel-default>.panel-heading h4{
    float:left;
    line-height: 1.25;
  }

  .audit_results .panel-group .panel-default>.panel-heading h6{
    float:right;
  }

  #results_table .panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #ddd;
    background: #c1c1c1;
  }

  .center{
    display:block;
    margin:0 auto;
  }

  .popover .popover-title {
      color: black;
  }

  .mod a::after {
    display: inline-block;
    content: "";
    min-width: 1rem;
    min-height: 1rem;
    border-radius: 1rem;
    margin: 0 0.5rem;
    padding: 0.2rem 0.4rem;
    color: white;
    font-size: 1rem;
  }

  .mod.new-line a::after {
    content: "NL";
    background: rgb(1, 150, 230);
  }

  .mod.line-modified a::after {
    content: "MOD";
    background: #1bbc9b;
  }

/* BACC VIEWER
====================*/

    #baccViewer .col-md-8 {
      overflow-x: auto;
      overflow-y: hidden;
    }

    #baccViewerNav,
    .flex-row .note {
      height: 100%;
    }

    .tab-pane {
      margin: 0;
    }

    .saffron-note {
      background: rgb(255, 246, 210);
    }

    .blue-note {
      background: rgb(229, 244, 255);
    }

/* MODEM TOOLS
====================*/

      #modem_menu{
        overflow: auto;
      }

    #history_btn a{
      color: white;
    }

/*==============================================================================
  = GLOBAL OVERRIDES */

  /* TOP MENU
  ============*/

    /* nav logo */
    .page-header.navbar .page-logo .logo-default {
      margin: 10px 0 0;
      width: 115px;
    }

  /* SIDEBAR
  ===========*/

    /* nav item active */
    #sideNavigation .page-sidebar .page-sidebar-menu > li.active.open > a,
    #sideNavigation .page-sidebar .page-sidebar-menu > li.active > a,
    #sideNavigation .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a,
    #sideNavigation .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a {
      background: rgb(0, 164, 153); /* mcc-mint */
    } /* &:hover */
      #sideNavigation .page-sidebar .page-sidebar-menu > li.active.open > a:hover,
      #sideNavigation .page-sidebar .page-sidebar-menu > li.active > a:hover,
      #sideNavigation .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a:hover,
      #sideNavigation .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a:hover {
        background: rgb(0, 215, 201); /* mcc-mint-ALT */
      }

      /* pace page loading bar */
      #login .pace .pace-progress {
        top: 0;
      }

      .pace .pace-progress {
        background: rgb(214, 0, 28); /* mcc-mint */
      }

      /* pace page loading spinner */
      .pace .pace-activity {
        border-top-color: rgb(214, 0, 28); /* mcc-mint */
        border-left-color: rgb(214, 0, 28); /* mcc-mint */
      }

      .page-header-fixed .pace .pace-progress {
        top: 50px;
      }

      .page-header-fixed .pace .pace-activity {
        top: 54px;
      }

      /* lookup spinner */
      .block-spinner-bar>div,
      .page-spinner-bar>div {
        background-color: rgb(214, 0, 28) !important;
      }

      /* panel tool icons focus state (underline) fix */
      .tools a {
        text-decoration: none;
      }

      /* sidebar menu */
      ul.page-sidebar-menu {
        padding-top: 0 !important;
      }

      /* increase footers z-index
          -- fix fullscreen overlay from showing on top of fixed footer
          -- had to further increase value to allow footer to overlay over uiblock div overlays
      */

      .page-footer-fixed .page-footer {
        z-index: 9999999;
      }

      /* tooltip arrow */
      .tooltip.right .tooltip-arrow,
      .tooltip.left .tooltip-arrow {
        border-right-color: rgba(0, 0, 0, 0.7);
        border-left-color: rgba(0, 0, 0, 0.7);
      }

      .tooltip.top .tooltip-arrow {
        border-top-color: rgba(0, 0, 0, 0.7);
      }

      /* tooltip */
      .has-tooltip + .tooltip > .tooltip-inner {
        background: rgb(61, 61, 61);
        box-shadow: 0 1px 3px rgba(0,0,0,0.12),
                    0 1px 2px rgba(0,0,0,0.24) !important;
      }



      /* for things that look like buttons but aren't buttons */
      span.btn:hover {
        cursor: default;
      }

.center{text-align:center}

.changed{
  background-color: #add2cf;
}

.category_click{
    color: blue;
    cursor: pointer;
    font-weight: bold;
}

.breakout{
    background-color: #519ad8;
    color: white;
}

.panel-heading:hover {
  cursor: pointer;
}

.btn-search:disabled,
.btn-search.disabled {
  opacity: 1;
  background: #6bd3be !important;
}

.refreshResults,.clearResults {
  padding-left:20px;
}

.clearResults{
  padding-left:35px;
}

.panel-title a.nodata{
    cursor:default;
    padding: 10px 15px;
    padding-right: 20px;
    background-color: #d0c6c6;
  }

  div.nodata{
    background-color: #d0c6c6;
    text-align: center;
    padding-bottom: 5px;
  }

  .phoneSelectForm{
    display:none;
  }

@media(max-width:991px){
  .search-utl-btn-group {
    transform: translate(0px, 0px);
    margin-bottom:10px;
  }

  .refreshResults{
    border-radius: 10px !important;
    padding-left:10px;
    transform:0px;
  }

  .clearResults {
      border-radius: 10px !important;
      padding-left: 10px;
      text-align: right;
      transform: translateX(0px);
      margin-left: 10px !important;
  }

}

  div.nodata{
    background-color: #d0c6c6;
    text-align: center;
    padding-bottom: 5px;
  }

  #product_info_wrapper{
    word-wrap: break-word;
    clear: both;
  }


